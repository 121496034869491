
.img-fluid {
  max-width: 56%;
  height: auto;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgb(64, 64, 64);
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #e46934;
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgb(228, 107, 56);
}
.mobileCart {
  display: none;
  margin-left: -24px;
}
@media only screen and (max-width: 990px) {
.mobileCart {
    display: inline;
}
}
@media (width: 360px) {
.mobileCart {
    margin-left: -129px;
}
}
@media (width: 375px) {
.mobileCart {
    margin-left: -129px;
}
}
@media (width: 768px) {
button.navbar-toggler {
    padding-left: 45% !important;
}
}

.main-section[data-v-05c62144] {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(36, 39, 38, 0)), to(rgba(36, 39, 38, 0))),
    rgba(36, 39, 38, 0.88) url(/static/images/hero.jpeg) no-repeat center;
  background: linear-gradient(rgba(36, 39, 38, 0), rgba(36, 39, 38, 0)),
    rgba(36, 39, 38, 0.88) url(/static/images/hero.jpeg) no-repeat center;
  padding: 101px 0;
  background-size: cover;
}
.mt-5[data-v-05c62144],
.my-5[data-v-05c62144] {
  margin-top: 10rem !important;
}
.designBtn[data-v-05c62144] {
  background-image: linear-gradient(122deg, #f6b87f 0%, #f8a382 100%);
  font-size: 35px;
  margin-top: 69px;
  border-radius: 34px;
}
.headline[data-v-05c62144] {
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 1);
  color: #404040;
  font-weight: 500;
}
@media (max-width: 450px) {
.designBtn[data-v-05c62144] {
    font-size: 29px;
}
}

.stepHr[data-v-6501361f] {
  width: 20%;
  border-top: 2px dashed rgb(248, 164, 129);
}

.text-primary[data-v-0f534126] {
  color: #e77d50 !important;
}

@media (min-width: 992px) {
.col-lg-4[data-v-4b5fb137] {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 24.333333%;
}
}
.section[data-v-4b5fb137] {
  padding: 116px 0;
}
.steps[data-v-4b5fb137] {
  text-align: center;
}
.step1[data-v-4b5fb137] {
  background: #e67547;
  padding: 12px;
  border-right: 1px dashed #f8b17f;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.step2[data-v-4b5fb137] {
  background: #c7c7c766;
  padding: 12px;
  border-right: 1px dashed #f8b17f;
}
.step3[data-v-4b5fb137] {
  background: #c7c7c766;
  padding: 12px;
  border-right: 1px dashed #f8b17f;
}
.step4[data-v-4b5fb137] {
  background: #c7c7c766;
  padding: 12px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.stepTitle[data-v-4b5fb137] {
  font-size: 22px;
}
.disableStep[data-v-4b5fb137] {
  color: #ded3d3;
}
.btn-primary:not([disabled]):not(.disabled).active[data-v-4b5fb137],
.btn-primary[data-v-4b5fb137]:not([disabled]):not(.disabled):active,
.show > .btn-primary.dropdown-toggle[data-v-4b5fb137] {
  color: #fff;
  background-color: #49bf68;
  border-color: #48c067;
  -webkit-box-shadow: 0 0 0 0.2rem rgb(72, 192, 103);
          box-shadow: 0 0 0 0.2rem rgb(72, 192, 103);
}
@media (max-width: 760px) {
.step1[data-v-4b5fb137] {
    background: #e67547;
    padding: 4px;
    border-right: 1px dashed #f8b17f;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.step2[data-v-4b5fb137] {
    padding: 0;
    display: none;
}
.step3[data-v-4b5fb137] {
    padding: 0;
    display: none;
}
.step4[data-v-4b5fb137] {
    padding: 0px;
    border-top-right-radius: 0;
    display: none;
    border-bottom-right-radius: 0;
}
}

@media (min-width: 992px) {
.col-lg-4[data-v-049ce604] {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 24.333333%;
}
}
.section[data-v-049ce604] {
  padding: 116px 0;
}
.steps[data-v-049ce604] {
  text-align: center;
}
.step1[data-v-049ce604] {
  background: #e67547;
  padding: 12px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-right: 1px dashed #f8b17f;
}
.step2[data-v-049ce604] {
  background: #e67547;
  padding: 12px;
  border-right: 1px dashed #f8b17f;
}
.step3[data-v-049ce604] {
  background: #c7c7c766;
  padding: 12px;
  border-right: 1px dashed #f8b17f;
}
.step4[data-v-049ce604] {
  background: #c7c7c766;
  padding: 12px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.stepTitle[data-v-049ce604] {
  font-size: 22px;
}
.disableStep[data-v-049ce604] {
  color: #ded3d3;
}
.btn-primary:not([disabled]):not(.disabled).active[data-v-049ce604],
.btn-primary[data-v-049ce604]:not([disabled]):not(.disabled):active,
.show > .btn-primary.dropdown-toggle[data-v-049ce604] {
  color: #fff;
  background-color: #49bf68;
  border-color: #48c067;
  -webkit-box-shadow: 0 0 0 0.2rem rgb(72, 192, 103);
          box-shadow: 0 0 0 0.2rem rgb(72, 192, 103);
}
input[type="checkbox"][data-v-049ce604] {
  position: relative;
  cursor: pointer;
}
input[type="checkbox"][data-v-049ce604]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -1px;
  left: -2px;
  border: 2px solid #e48339;
  border-radius: 3px;
  background-color: white;
}
input[type="checkbox"][data-v-049ce604]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 0px;
  left: 3px;
}
input[type="checkbox"][data-v-049ce604]:disabled:before {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid red;
  background-color: red;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 3px;
  left: 4px;
}
label[data-v-049ce604] {
  display: inline-block;
  margin-bottom: 0;
  padding-left: 10px;
}
.btn-is-disabled[data-v-049ce604] {
  pointer-events: none;
  /* Disables the button completely. Better than just cursor: default; */
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
}
@media (max-width: 760px) {
.step1[data-v-049ce604] {
    background: #e67547;
    padding: 4px;
    display: none;
    border-right: 1px dashed #f8b17f;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.step2[data-v-049ce604] {
    padding: 0;
}
.step3[data-v-049ce604] {
    padding: 0;
    display: none;
}
.step4[data-v-049ce604] {
    padding: 0px;
    display: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
}
@media (max-width: 560px) {
.col-lg-6.col-md-6.col-sm-12.col-xs-12[data-v-049ce604] {
    padding-bottom: 10px;
}
}

@media (min-width: 992px) {
.col-lg-4[data-v-8b958172] {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 24.333333%;
}
}
.section[data-v-8b958172] {
  padding: 116px 0;
}
.steps[data-v-8b958172] {
  text-align: center;
}
.step1[data-v-8b958172] {
  background: #e67547;
  padding: 12px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-right: 1px dashed #f8b17f;
}
.step2[data-v-8b958172] {
  background: #e67547;
  padding: 12px;
  border-right: 1px dashed #f8b17f;
}
.step3[data-v-8b958172] {
  background: #e67547;
  padding: 12px;
  border-right: 1px dashed #f8b17f;
}
.step4[data-v-8b958172] {
  background: #c7c7c766;
  padding: 12px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.stepTitle[data-v-8b958172] {
  font-size: 22px;
}
.disableStep[data-v-8b958172] {
  color: #ded3d3;
}
.btn-primary:not([disabled]):not(.disabled).active[data-v-8b958172],
.btn-primary[data-v-8b958172]:not([disabled]):not(.disabled):active,
.show > .btn-primary.dropdown-toggle[data-v-8b958172] {
  color: #fff;
  background-color: #49bf68;
  border-color: #48c067;
  -webkit-box-shadow: 0 0 0 0.2rem rgb(72, 192, 103);
          box-shadow: 0 0 0 0.2rem rgb(72, 192, 103);
}
input[type="checkbox"][data-v-8b958172] {
  position: relative;
  cursor: pointer;
}
input[type="checkbox"][data-v-8b958172]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -1px;
  left: -2px;
  border: 2px solid #e48339;
  border-radius: 3px;
  background-color: white;
}
input[type="checkbox"][data-v-8b958172]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 0px;
  left: 3px;
}
input[type="checkbox"][data-v-8b958172]:disabled:before {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid red;
  background-color: red;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 3px;
  left: 4px;
}
label[data-v-8b958172] {
  display: inline-block;
  margin-bottom: 0;
  padding-left: 10px;
}
.btn-is-disabled[data-v-8b958172] {
  pointer-events: none;
  /* Disables the button completely. Better than just cursor: default; */
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
}
@media (max-width: 760px) {
.step1[data-v-8b958172] {
    background: #e67547;
    padding: 4px;
    display: none;
    border-right: 1px dashed #f8b17f;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.step2[data-v-8b958172] {
    padding: 0;
    display: none;
}
.step3[data-v-8b958172] {
    padding: 0;
}
.step4[data-v-8b958172] {
    padding: 0px;
    border-top-right-radius: 0;
    display: none;
    border-bottom-right-radius: 0;
}
}
@media (max-width: 560px) {
.col-lg-6.col-md-6.col-sm-12.col-xs-12[data-v-8b958172] {
    padding-bottom: 10px;
}
ul.list-group.list-group-flush.col-lg-12.col-md-12.col-sm-12.col-xs-12[data-v-8b958172] {
    padding-left: 19% !important;
}
}

@media (min-width: 992px) {
.col-lg-4[data-v-3242bcb3] {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 24.333333%;
}
}
.section[data-v-3242bcb3] {
  padding: 116px 0;
}
.steps[data-v-3242bcb3] {
  text-align: center;
}
.step1[data-v-3242bcb3] {
  background: #e67547;
  padding: 12px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-right: 1px dashed #f8b17f;
}
.step2[data-v-3242bcb3] {
  background: #e67547;
  padding: 12px;
  border-right: 1px dashed #f8b17f;
}
.step3[data-v-3242bcb3] {
  background: #e67547;
  padding: 12px;
  border-right: 1px dashed #f8b17f;
}
.step4[data-v-3242bcb3] {
  background: #e67547;
  padding: 12px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.stepTitle[data-v-3242bcb3] {
  font-size: 22px;
}
.disableStep[data-v-3242bcb3] {
  color: #ded3d3;
}
.btn-primary:not([disabled]):not(.disabled).active[data-v-3242bcb3],
.btn-primary[data-v-3242bcb3]:not([disabled]):not(.disabled):active,
.show > .btn-primary.dropdown-toggle[data-v-3242bcb3] {
  color: #fff;
  background-color: #49bf68;
  border-color: #48c067;
  -webkit-box-shadow: 0 0 0 0.2rem rgb(72, 192, 103);
          box-shadow: 0 0 0 0.2rem rgb(72, 192, 103);
}
input[type="checkbox"][data-v-3242bcb3] {
  position: relative;
  cursor: pointer;
}
input[type="checkbox"][data-v-3242bcb3]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -1px;
  left: -2px;
  border: 2px solid #e48339;
  border-radius: 3px;
  background-color: white;
}
.card.pricing .list-group-item[data-v-3242bcb3] {
  border: 0;
  text-align: center;
  color: #959094;
  padding: 0.05rem 1.25rem;
}
input[type="checkbox"][data-v-3242bcb3]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 0px;
  left: 3px;
}
input[type="checkbox"][data-v-3242bcb3]:disabled:before {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid red;
  background-color: red;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: -1px;
  left: 2px;
}
label[data-v-3242bcb3] {
  display: inline-block;
  margin-bottom: 0;
  padding-left: 10px;
}
.list-group-item[data-v-3242bcb3] {
  text-align: left;
}
.stepHr[data-v-3242bcb3] {
  width: 20%;
  border-top: 2px dashed rgb(248, 164, 129);
}
@media (max-width: 760px) {
.step1[data-v-3242bcb3] {
    background: #e67547;
    padding: 4px;
    display: none;
    border-right: 1px dashed #f8b17f;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.step2[data-v-3242bcb3] {
    padding: 0;
    display: none;
}
.step3[data-v-3242bcb3] {
    padding: 0;
    display: none;
}
.step4[data-v-3242bcb3] {
    padding: 0px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
}
@media (max-width: 560px) {
.col-lg-6.col-md-6.col-sm-12.col-xs-12[data-v-3242bcb3] {
    padding-bottom: 12px;
}
ul.list-group.list-group-flush.col-lg-6.col-md-6.col-sm-6.col-xs-6[data-v-3242bcb3] {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
    margin-left: 26px !important;
}
.col-lg-6.col-md-6.col-sm-6.col-xs-6[data-v-3242bcb3] {
    padding-right: 57px;
}
}

a.snipcart-add-item.btn.btn.btn-primary[data-v-c62947d6] {
  border-radius: 27px;
  background-image: linear-gradient(122deg, #a1c105 0%, #96b112 100%);
}
@media (max-width: 560px) {
.snipbutton[data-v-c62947d6] {
    padding-top: 36px;
    padding-bottom: 20px;
}
}

.section[data-v-6363b4c5] {
  padding: 180px 0;
}
@media (max-width: 560px) {
.section[data-v-6363b4c5] {
    padding: 29px 0;
}
a.btn.btn-primary[data-v-6363b4c5] {
    margin-left: 110px;
}
}

@media (min-width: 992px) {
.col-lg-4[data-v-08e859dc] {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 24.333333%;
}
}
.section[data-v-08e859dc] {
  padding: 116px 0;
}
.btn-primary:not([disabled]):not(.disabled).active[data-v-08e859dc],
.btn-primary[data-v-08e859dc]:not([disabled]):not(.disabled):active,
.show > .btn-primary.dropdown-toggle[data-v-08e859dc] {
  color: #fff;
  background-color: #49bf68;
  border-color: #48c067;
  -webkit-box-shadow: 0 0 0 0.2rem rgb(72, 192, 103);
          box-shadow: 0 0 0 0.2rem rgb(72, 192, 103);
}
input[type="checkbox"][data-v-08e859dc] {
  position: relative;
  cursor: pointer;
}
input[type="checkbox"][data-v-08e859dc]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -2px;
  left: -4px;
  border: 2px solid #e48339;
  border-radius: 3px;
  background-color: white;
}
input[type="checkbox"][data-v-08e859dc]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: -1px;
  left: 2px;
}
input[type="checkbox"][data-v-08e859dc]:disabled:before {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid red;
  background-color: red;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: -1px;
  left: 2px;
}
label[data-v-08e859dc] {
  display: inline-block;
  margin-bottom: 0;
  padding-left: 10px;
}
#contact input[type="text"][data-v-08e859dc],
#contact input[type="email"][data-v-08e859dc],
#contact input[type="tel"][data-v-08e859dc],
#contact input[type="url"][data-v-08e859dc],
#contact textarea[data-v-08e859dc],
#contact button[type="submit"][data-v-08e859dc] {
  font: 400 12px/16px "Roboto", Helvetica, Arial, sans-serif;
}
#contact[data-v-08e859dc] {
  background: #f9d6b4;
  padding: 25px;
  margin: 0px 0;
  border-radius: 15px;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
#contact h3[data-v-08e859dc] {
  display: block;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 10px;
}
#contact h4[data-v-08e859dc] {
  margin: 5px 0 15px;
  display: block;
  font-size: 13px;
  font-weight: 400;
}
fieldset[data-v-08e859dc] {
  border: medium none !important;
  margin: 0 0 10px;
  min-width: 100%;
  padding: 0;
  width: 100%;
}
#contact input[type="text"][data-v-08e859dc],
#contact input[type="email"][data-v-08e859dc],
#contact input[type="tel"][data-v-08e859dc],
#contact input[type="url"][data-v-08e859dc],
#contact textarea[data-v-08e859dc] {
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  margin: 0 0 5px;
  padding: 10px;
}
#contact input[type="text"][data-v-08e859dc]:hover,
#contact input[type="email"][data-v-08e859dc]:hover,
#contact input[type="tel"][data-v-08e859dc]:hover,
#contact input[type="url"][data-v-08e859dc]:hover,
#contact textarea[data-v-08e859dc]:hover {
  -webkit-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  border: 1px solid #aaa;
}
#contact textarea[data-v-08e859dc] {
  height: 100px;
  max-width: 100%;
  resize: none;
}
#contact button[type="submit"][data-v-08e859dc] {
  cursor: pointer;
  width: 100%;
  border: none;
  background: #4caf50;
  color: #fff;
  margin: 0 0 5px;
  padding: 10px;
  font-size: 15px;
}
#contact button[type="submit"][data-v-08e859dc]:hover {
  background: #43a047;
  -webkit-transition: background 0.3s ease-in-out;
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}
#contact button[type="submit"][data-v-08e859dc]:active {
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}
.copyright[data-v-08e859dc] {
  text-align: center;
}
#contact input[data-v-08e859dc]:focus,
#contact textarea[data-v-08e859dc]:focus {
  outline: 0;
  border: 1px solid #aaa;
}
[data-v-08e859dc]::-webkit-input-placeholder {
  color: #888;
}
[data-v-08e859dc]:-moz-placeholder {
  color: #888;
}
[data-v-08e859dc]::-moz-placeholder {
  color: #888;
}
[data-v-08e859dc]:-ms-input-placeholder {
  color: #888;
}

@media (min-width: 992px) {
.col-lg-4[data-v-5f283788] {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 24.333333%;
}
}
.section[data-v-5f283788] {
  padding: 116px 0;
}
.steps[data-v-5f283788] {
  text-align: center;
}
.step1[data-v-5f283788] {
  background: #e67547;
  padding: 12px;
  border-right: 1px dashed #f8b17f;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.step2[data-v-5f283788] {
  background: #c7c7c766;
  padding: 12px;
  border-right: 1px dashed #f8b17f;
}
.step3[data-v-5f283788] {
  background: #c7c7c766;
  padding: 12px;
  border-right: 1px dashed #f8b17f;
}
.step4[data-v-5f283788] {
  background: #c7c7c766;
  padding: 12px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.stepTitle[data-v-5f283788] {
  font-size: 22px;
}
.disableStep[data-v-5f283788] {
  color: #ded3d3;
}
.btn-primary:not([disabled]):not(.disabled).active[data-v-5f283788],
.btn-primary[data-v-5f283788]:not([disabled]):not(.disabled):active,
.show > .btn-primary.dropdown-toggle[data-v-5f283788] {
  color: #fff;
  background-color: #49bf68;
  border-color: #48c067;
  -webkit-box-shadow: 0 0 0 0.2rem rgb(72, 192, 103);
          box-shadow: 0 0 0 0.2rem rgb(72, 192, 103);
}
@media (max-width: 760px) {
.step1[data-v-5f283788] {
    background: #e67547;
    padding: 4px;
    border-right: 1px dashed #f8b17f;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.step2[data-v-5f283788] {
    padding: 0;
    display: none;
}
.step3[data-v-5f283788] {
    padding: 0;
    display: none;
}
.step4[data-v-5f283788] {
    padding: 0px;
    border-top-right-radius: 0;
    display: none;
    border-bottom-right-radius: 0;
}
}
.img-fluid[data-v-5f283788] {
  max-width: 67%;
  height: auto;
}
